import React from "react"
import styled from "styled-components"
import { PageTitle, Seo, SingleProject } from "../../components/common"
import { Layout } from "../../components/layout"
import { useContentfulProjects } from "../../hooks"
import { Banner } from "../../components/sections"

const ProjectsPage = () => {
  const projects = useContentfulProjects()

  return (
    <>
      <Seo title={"Project"} />

      <Layout>
        <PageTitle title="Latest Projects" />
        <Container className="section shaped-bcg-svg">
          <SectionCenter className="section-center">
            {projects?.map(project => (
              <SingleProject key={project?.id} project={project} />
            ))}
          </SectionCenter>
        </Container>
        <Banner />
      </Layout>
    </>
  )
}

const Container = styled.section`
  background-color: var(--clr-black2);
`

const SectionCenter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media (min-width: 792px) {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
  @media (min-width: 1170px) {
    gap: 6rem 10rem;
  }
`

export default ProjectsPage
